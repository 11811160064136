import React from "react";

const Css3 = props => (
  <svg id="svg3476" viewBox="0 0 290.2 330.1" {...props}>
    <path
      id="polygon2989"
      fill="#264de4"
      d="M290.2 1l-26.4 296.2-118.9 32.9-118.5-32.9L0 1z"
    />
    <path
      id="polygon2991"
      fill="#2965f1"
      d="M241.1 278.3l22.6-253.1H145.1v279.7z"
    />
    <path
      id="polygon2993"
      fill="#ebebeb"
      className="st2"
      d="M60.5 135.1l3.3 36.3h81.3v-36.3z"
    />
    <path
      id="polygon2995"
      fill="#ebebeb"
      className="st2"
      d="M145.1 61.5H54l3.3 36.4h87.8z"
    />
    <path
      id="polygon2997"
      fill="#ebebeb"
      className="st2"
      d="M145.1 267.2v-37.8l-.2.1-40.4-11-2.6-28.9H65.4l5.1 57 74.4 20.7z"
    />
    <path
      id="polygon3005"
      fill="#fff"
      d="M189.7 171.4l-4.2 47.1-40.5 11v37.8l74.4-20.7.6-6.1 8.5-95.7.9-9.7 6.6-73.6h-91v36.4h51.2l-3.3 37.2H145v36.3z"
    />
  </svg>
);

export default Css3;
