const freelances = [
  {
    name: "Tahnamão",
    summary: ["work.tahnamao.p1", "work.tahnamao.p2"],
    href: "https://play.google.com/store/apps/details?id=com.tahnamao",
    date: new Date(2020, 5),
    techs: ["React Native", "Payment Integration", "Chat", "OneSignal"]
  },
  {
    name: "STREQ25",
    summary: ["work.streq.p1", "work.streq.p2"],
    href: "https://play.google.com/store/apps/details?id=com.inovando.streq",
    date: new Date(2019, 11),
    techs: ["React Native"]
  },
  {
    name: "QVT25",
    summary: ["work.qvt.p1", "work.qvt.p2"],
    href: "https://play.google.com/store/apps/details?id=com.inovando.qvt",
    date: new Date(2019, 11),
    techs: ["React Native"]
  },
  {
    name: "Clinicarx",
    summary: ["work.clinicarx.p1", "work.clinicarx.p2"],
    href: "https://apps.apple.com/br/app/clinicarx/id1482164511?l=en",
    date: new Date(2019, 10),
    techs: ["React Native", "Firebase", "iOS/Android"]
  },
  {
    name: "Giveit",
    summary: ["work.giveit.p1", "work.giveit.p2"],
    date: new Date(2017, 12),
    techs: ["React", "React Native", "Node.js"]
  },
  {
    name: "Red Week Ideas",
    summary: ["work.redweekideas.p1"],
    href: "http://redweekideas.com/",
    date: new Date(2016, 11),
    techs: ["HTML5", "CSS3", "Javascript", "Google Maps"]
  }
];

export default freelances;
