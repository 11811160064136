import React from "react";

const Fb = props => (
  <svg viewBox="0 0 96.1 96.1" {...props}>
    <path
      d="M72.1 0H59.6c-14 0-23.1 9.3-23.1 23.7v10.9H24c-1.1 0-2 .9-2 2v15.8c0 1.1.9 2 2 2h12.5v39.9c0 1.1.9 2 2 2h16.4c1.1 0 2-.9 2-2v-40h14.7c1.1 0 2-.9 2-2V36.5c0-.5-.2-1-.6-1.4s-.9-.6-1.4-.6H56.8v-9.2c0-4.4 1.1-6.7 6.8-6.7H72c1.1 0 2-.9 2-2V2c0-1.1-.8-2-1.9-2z"
      fill="#fff"
    />
  </svg>
);

export default Fb;
