import React from "react";

const Illustrator = props => (
  <svg viewBox="0 0 500 485.9" {...props}>
    <path d="M20.8 19.8h457.7v445.3H20.8V19.8z" fill="#230a00" />
    <path
      d="M20.8 19.8h457.7v445.3H20.8V19.8zM0 485.9h499.4V-1H0v486.9zm327.1-313.3c0-1.7.6-2.5 2.5-2.5h32.7c1.7 0 2.5.6 2.5 2.5v164.2c0 1.7-.4 2.5-2.5 2.5H330c-2.1 0-2.7-1-2.7-2.7v-164h-.2zm-2.3-47.3c0-13.3 9.4-21.2 21.2-21.2 12.7 0 21.2 8.5 21.2 21.2 0 13.7-8.9 21.2-21.6 21.2-12 .1-20.8-7.4-20.8-21.2zM231.2 241c-5.8-23.1-19.6-73.4-24.8-97.8h-.4c-4.4 24.3-15.4 65.5-24.1 97.8h49.3zm-57.8 33.7L157 337.1c-.4 1.7-1 2.1-3.1 2.1h-30.6c-2.1 0-2.5-.6-2.1-3.1l59.1-206.8c1-3.7 1.7-7.1 2.1-17.3 0-1.5.6-2.1 1.7-2.1h43.7c1.5 0 2.1.4 2.5 2.1l66.2 224.5c.4 1.7 0 2.7-1.7 2.7h-34.5c-1.7 0-2.7-.4-3.1-1.9l-17.1-62.6h-66.7z"
      fill="#ff7f18"
    />
  </svg>
);

export default Illustrator;
