import {
  React,
  CSS3,
  HTML5,
  Illustrator,
  Photoshop,
  Redux,
  Sass,
  ReactNative,
  Javascript,
  Nodejs,
  Vuejs,
  Webpack,
  Babel,
  Typescript,
  Figma
} from "./svg";

const skills = [
  {
    icon: React,
    title: "React"
  },
  {
    icon: Redux,
    title: "Redux"
  },
  {
    icon: Vuejs,
    title: "Vue.js"
  },
  {
    icon: ReactNative,
    title: "React Native"
  },
  {
    icon: Figma,
    title: "Figma"
  },
  {
    icon: Nodejs,
    title: "Node.js"
  },
  {
    icon: Webpack,
    title: "Webpack"
  },
  {
    icon: Babel,
    title: "Babel"
  },
  {
    icon: Typescript,
    title: "Typescript"
  },
  {
    icon: Javascript,
    title: "Javascript"
  },
  {
    icon: CSS3,
    title: "CSS3"
  },
  {
    icon: Sass,
    title: "Sass"
  },
  {
    icon: HTML5,
    title: "HTML5"
  },
  {
    icon: Photoshop,
    title: "Photoshop"
  },
  {
    icon: Illustrator,
    title: "Illustrator"
  }
];

export default skills;
