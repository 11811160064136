import React from "react";

const Behance = props => (
  <svg viewBox="0 0 430.1 430.1" {...props}>
    <path
      d="M388.4 119.1H280.7V92.3h107.8l-.1 26.8zM208.9 228.9c7 10.8 10.4 23.8 10.4 39.2 0 15.9-3.9 30.1-11.9 42.7-5.1 8.3-11.4 15.4-18.9 21-8.5 6.5-18.6 11-30.2 13.4-11.6 2.4-24.2 3.6-37.8 3.6H0V81.2h129.2c32.6.5 55.7 10 69.3 28.5 8.2 11.4 12.2 25 12.2 40.9 0 16.4-4.1 29.5-12.4 39.4-4.6 5.6-11.4 10.6-20.4 15.2 13.8 5 24.1 12.9 31 23.7zM61.7 186.8h56.6c11.6 0 21-2.2 28.3-6.6 7.2-4.4 10.9-12.3 10.9-23.5 0-12.4-4.8-20.7-14.4-24.7-8.2-2.8-18.8-4.2-31.6-4.2H61.7v59zM163 264.3c0-13.9-5.7-23.5-17-28.7-6.3-2.9-15.3-4.4-26.8-4.5H61.7v71.3h56.6c11.6 0 20.6-1.5 27.1-4.7 11.7-5.8 17.6-16.9 17.6-33.4zm265.4-43.6c1.3 8.8 1.9 21.5 1.7 38.1H290.5c.8 19.3 7.4 32.7 20 40.4 7.6 4.8 16.8 7.2 27.6 7.2 11.4 0 20.7-2.9 27.8-8.8 3.9-3.1 7.3-7.6 10.3-13.2h51.2c-1.3 11.4-7.5 22.9-18.6 34.6-17.2 18.6-41.2 28-72.1 28-25.5 0-48-7.9-67.5-23.6-19.5-15.8-29.2-41.3-29.2-76.8 0-33.3 8.8-58.7 26.4-76.4 17.7-17.8 40.5-26.6 68.6-26.6 16.7 0 31.7 3 45.1 9 13.4 6 24.4 15.4 33.1 28.4 7.8 11.5 12.9 24.7 15.2 39.7zm-50.3 5c-.9-13.3-5.4-23.4-13.4-30.3-7.9-6.9-17.9-10.4-29.7-10.4-12.9 0-22.8 3.7-29.9 11-7.1 7.3-11.5 17.2-13.4 29.7h86.4z"
      fill="#fff"
    />
  </svg>
);

export default Behance;
