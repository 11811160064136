import React from "react";

const SvgMedium = props => (
  <svg fill="none" {...props}>
    <path
      d="M3.061 3.547a.732.732 0 0 0-.238-.617L1.059.805V.487h5.477l4.235 9.286L14.492.487h5.222v.318L18.206 2.25a.441.441 0 0 0-.167.423V13.3a.441.441 0 0 0 .167.423l1.474 1.447v.317h-7.41v-.317l1.526-1.482c.15-.15.15-.194.15-.423v-8.59L9.703 15.453H9.13L4.19 4.676v7.222c-.041.304.06.61.274.829l1.984 2.407v.318H.821v-.318l1.984-2.407a.96.96 0 0 0 .256-.829v-8.35z"
      fill="#fff"
    />
  </svg>
);

export default SvgMedium;

