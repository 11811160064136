import React from "react";

const Vuejs = props => (
  <svg viewBox="0 0 263.8 226" {...props}>
    <path
      d="M161.417-.278L131.15 51.989l-30.267-52.4H.217L131.15 226.255 262.083-.41H161.417z"
      fill="#4dba87"
    />
    <path
      d="M161.417-.278L131.15 51.989l-30.267-52.4H52.617l78.533 136 78.533-136h-48.266z"
      fill="#435466"
    />
  </svg>
);

export default Vuejs;
