import React from "react";

const Email = props => (
  <svg id="Capa_1" viewBox="0 0 14 14" {...props}>
    <path
      fill="#fff"
      className="st0"
      d="M7 9L5.3 7.5l-5 4.2c.2.2.4.3.7.3h12c.3 0 .5-.1.7-.3l-5-4.2L7 9z"
    />
    <path
      fill="#fff"
      className="st0"
      d="M13.7 2.3c-.2-.2-.4-.3-.7-.3H1c-.3 0-.5.1-.7.3L7 8l6.7-5.7zM0 2.9v8.3l4.8-4.1zM9.2 7.1l4.8 4.1V2.9z"
    />
  </svg>
);

export default Email;
