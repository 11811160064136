import React from "react";

const Linkedin = props => (
  <svg viewBox="0 0 430.1 430.1" {...props}>
    <path
      d="M430.1 261.5v159h-92.2V272.2c0-37.3-13.3-62.7-46.7-62.7-25.5 0-40.6 17.1-47.3 33.7-2.4 5.9-3.1 14.2-3.1 22.5v154.9h-92.2s1.2-251.3 0-277.3h92.2v39.3l-.6.9h.6v-.9c12.3-18.9 34.1-45.8 83.1-45.8 60.7-.1 106.2 39.6 106.2 124.7zM52.2 9.6C20.6 9.6 0 30.3 0 57.5c0 26.6 20 47.9 51 47.9h.6c32.2 0 52.2-21.3 52.2-47.9-.7-27.2-20.1-47.9-51.6-47.9zm-46.7 411h92.2V143.2H5.5v277.4z"
      fill="#fff"
    />
  </svg>
);

export default Linkedin;
