import React from "react";

const UnitedStates = props => (
  <svg id="Layer_1" viewBox="0 0 21.4 15" {...props}>
    <path fill="#b22234" d="M0 0h21.4v15H0z" />
    <path d="M0 1.7h21.4m0 2.3H0m0 2.3h21.4m0 2.4H0M0 11h21.4m0 2.3H0" />
    <path
      className="st1"
      fill="#fff"
      d="M21.4 13.8H0v-1.2h21.4v1.2zm0-2.3H0v-1.2h21.4v1.2zm0-2.3H0V8.1h21.4v1.1zm0-2.3H0V5.8h21.4v1.1zm0-2.3H0V3.5h21.4v1.1zm0-2.3H0V1.2h21.4v1.1z"
    />
    <path fill="#3c3b6e" d="M0 0h11.4v8.1H0z" />
    <g>
      <g id="s5">
        <g id="s4">
          <path className="st1" d="M.9.3l.3.8L.5.7h.9l-.7.5.2-.9z" id="s" />
          <path className="st1" d="M.9 2l.3.8-.7-.5h.9l-.7.5.2-.8z" id="s_1_" />
          <path
            className="st1"
            d="M.9 3.6l.3.8-.7-.5h.9l-.7.5.2-.8z"
            id="s_2_"
          />
          <path
            className="st1"
            d="M.9 5.2l.3.8-.7-.5h.9L.7 6l.2-.8z"
            id="s_3_"
          />
        </g>
        <path className="st1" d="M.9 6.8l.3.8-.7-.5h.9l-.7.5.2-.8z" id="s_4_" />
      </g>
      <g id="s9">
        <g id="s4_1_">
          <path
            className="st1"
            d="M1.9 1.2l.3.8-.7-.5h.9l-.8.5.3-.8z"
            id="s_5_"
          />
          <path
            className="st1"
            d="M1.9 2.8l.3.8-.7-.5h.9l-.8.5.3-.8z"
            id="s_6_"
          />
          <path
            className="st1"
            d="M1.9 4.4l.3.8-.7-.5h.9l-.8.5.3-.8z"
            id="s_7_"
          />
          <path
            className="st1"
            d="M1.9 6l.3.8-.7-.5h.9l-.8.5.3-.8z"
            id="s_8_"
          />
        </g>
      </g>
      <g id="s18">
        <g id="s5_1_">
          <g id="s4_2_">
            <path
              className="st1"
              d="M2.8.3l.3.8-.7-.4h.9l-.7.5.2-.9z"
              id="s_9_"
            />
            <path
              className="st1"
              d="M2.8 2l.3.8-.7-.5h.9l-.7.5.2-.8z"
              id="s_10_"
            />
            <path
              className="st1"
              d="M2.8 3.6l.3.8-.7-.5h.9l-.7.5.2-.8z"
              id="s_11_"
            />
            <path
              className="st1"
              d="M2.8 5.2l.3.8-.7-.5h.9l-.7.5.2-.8z"
              id="s_12_"
            />
          </g>
          <path
            className="st1"
            d="M2.8 6.8l.3.8-.7-.5h.9l-.7.5.2-.8z"
            id="s_13_"
          />
        </g>
        <g id="s9_1_">
          <g id="s4_3_">
            <path
              className="st1"
              d="M3.8 1.2l.3.8-.7-.5h.9l-.8.5.3-.8z"
              id="s_14_"
            />
            <path
              className="st1"
              d="M3.8 2.8l.3.8-.7-.5h.9l-.8.5.3-.8z"
              id="s_15_"
            />
            <path
              className="st1"
              d="M3.8 4.4l.3.8-.7-.5h.9l-.8.5.3-.8z"
              id="s_16_"
            />
            <path
              className="st1"
              d="M3.8 6l.3.8-.7-.5h.9l-.8.5.3-.8z"
              id="s_17_"
            />
          </g>
        </g>
      </g>
      <g id="s5_2_">
        <g id="s4_4_">
          <path
            className="st1"
            d="M4.8.3l.2.9-.7-.5h.9l-.7.5.3-.9z"
            id="s_18_"
          />
          <path
            className="st1"
            d="M4.8 2l.2.8-.7-.5h.9l-.7.5.3-.8z"
            id="s_19_"
          />
          <path
            className="st1"
            d="M4.8 3.6l.2.8-.7-.5h.9l-.7.5.3-.8z"
            id="s_20_"
          />
          <path
            className="st1"
            d="M4.8 5.2L5 6l-.7-.5h.9l-.7.5.3-.8z"
            id="s_21_"
          />
        </g>
        <path
          className="st1"
          d="M4.8 6.8l.2.8-.7-.5h.9l-.7.5.3-.8z"
          id="s_22_"
        />
      </g>
      <g id="s9_2_">
        <g id="s4_5_">
          <path
            className="st1"
            d="M5.7 1.2L6 2l-.7-.5h.9l-.8.5.3-.8z"
            id="s_23_"
          />
          <path
            className="st1"
            d="M5.7 2.8l.3.8-.7-.5h.9l-.8.5.3-.8z"
            id="s_24_"
          />
          <path
            className="st1"
            d="M5.7 4.4l.3.8-.7-.5h.9l-.8.5.3-.8z"
            id="s_25_"
          />
          <path
            className="st1"
            d="M5.7 6l.3.8-.7-.5h.9l-.8.5.3-.8z"
            id="s_26_"
          />
        </g>
      </g>
      <g id="s18_1_">
        <g id="s5_3_">
          <g id="s4_6_">
            <path
              className="st1"
              d="M6.7.3l.3.8-.8-.4h.9l-.7.5.3-.9z"
              id="s_27_"
            />
            <path
              className="st1"
              d="M6.7 2l.3.8-.8-.5h.9l-.7.5.3-.8z"
              id="s_28_"
            />
            <path
              className="st1"
              d="M6.7 3.6l.3.8-.8-.5h.9l-.7.5.3-.8z"
              id="s_29_"
            />
            <path
              className="st1"
              d="M6.7 5.2l.2.8-.7-.5h.9l-.7.5.3-.8z"
              id="s_30_"
            />
          </g>
          <path
            className="st1"
            d="M6.7 6.8l.3.8-.8-.5h.9l-.7.5.3-.8z"
            id="s_31_"
          />
        </g>
        <g id="s9_3_">
          <g id="s4_7_">
            <path
              className="st1"
              d="M7.6 1.2l.3.8-.7-.5H8l-.7.5.3-.8z"
              id="s_32_"
            />
            <path
              className="st1"
              d="M7.6 2.8l.3.8-.7-.5H8l-.7.5.3-.8z"
              id="s_33_"
            />
            <path
              className="st1"
              d="M7.6 4.4l.3.8-.7-.5H8l-.7.5.3-.8z"
              id="s_34_"
            />
            <path
              className="st1"
              d="M7.6 6l.3.8-.7-.5H8l-.7.5.3-.8z"
              id="s_35_"
            />
          </g>
        </g>
      </g>
      <g id="s5_4_">
        <g id="s4_8_">
          <path
            className="st1"
            d="M8.6.3l.3.8-.8-.4H9l-.7.5.3-.9z"
            id="s_36_"
          />
          <path
            className="st1"
            d="M8.6 2l.3.8-.8-.5H9l-.7.5.3-.8z"
            id="s_37_"
          />
          <path
            className="st1"
            d="M8.6 3.6l.3.8-.8-.5H9l-.7.5.3-.8z"
            id="s_38_"
          />
          <path
            className="st1"
            d="M8.6 5.2l.2.8-.7-.5H9l-.7.5.3-.8z"
            id="s_39_"
          />
        </g>
        <path
          className="st1"
          d="M8.6 6.8l.3.8-.8-.5H9l-.7.5.3-.8z"
          id="s_40_"
        />
      </g>
      <g id="s9_4_">
        <g id="s4_9_">
          <path
            className="st1"
            d="M9.5 1.2l.3.8-.7-.5h.9l-.8.5.3-.8z"
            id="s_41_"
          />
          <path
            className="st1"
            d="M9.5 2.8l.3.8-.7-.5h.9l-.8.5.3-.8z"
            id="s_42_"
          />
          <path
            className="st1"
            d="M9.5 4.4l.3.8-.7-.5h.9l-.8.5.3-.8z"
            id="s_43_"
          />
          <path
            className="st1"
            d="M9.5 6l.3.8-.7-.5h.9l-.8.5.3-.8z"
            id="s_44_"
          />
        </g>
      </g>
      <g id="s5_5_">
        <g id="s4_10_">
          <path
            className="st1"
            d="M10.4.3l.3.8-.7-.4h.9l-.7.5.2-.9z"
            id="s_45_"
          />
          <path
            className="st1"
            d="M10.4 2l.3.8-.7-.5h.9l-.7.5.2-.8z"
            id="s_46_"
          />
          <path
            className="st1"
            d="M10.4 3.6l.3.8-.7-.5h.9l-.7.5.2-.8z"
            id="s_47_"
          />
          <path
            className="st1"
            d="M10.4 5.2l.3.8-.7-.5h.9l-.7.5.2-.8z"
            id="s_48_"
          />
        </g>
        <path
          className="st1"
          d="M10.4 6.8l.3.8-.7-.5h.9l-.7.5.2-.8z"
          id="s_49_"
        />
      </g>
    </g>
  </svg>
);

export default UnitedStates;
