import React from "react";

const Html5 = props => (
  <svg id="Layer_1" viewBox="0 0 1587.5 1777.7" {...props}>
    <path
      fill="#e34e26"
      d="M149.8 1613.1L5.7-3.3h1582.8l-144.3 1616.6-647.5 179.5z"
    />
    <path fill="#ef662a" d="M797.1 128.8v1526.7l523.6-145.2L1444 128.8z" />
    <path
      fill="#fff"
      d="M300.6 327.1L354 926.6h687.1l-23 257.2-221.3 59.8-220.8-59.7-14.8-165.1h-199l28.5 318.2 406.1 112.8 406.6-112.8 54.5-608.6H535.3l-18.1-203h758.7l17.7-198.3z"
    />
    <path
      className="st3"
      d="M797.1 327.1H300.6L354 926.6h443.1V728.4H535.3l-18.1-203h279.9zM797.1 1243.5l-.3.1-220.8-59.7-14.8-165.1h-199l28.5 318.2 406.1 112.8.3-.1z"
    />
  </svg>
);

export default Html5;
