import React from "react";

const Figma = props => (
  <svg viewBox="0 0 18.3 27.1" {...props}>
    <path
      d="M4.5 27C7 27 9 25 9 22.5V18H4.5C2 18 0 20 0 22.5S2 27 4.5 27z"
      fill="#0acf83"
    />
    <path d="M0 13.5C0 11 2 9 4.5 9H9v9H4.5C2 18 0 16 0 13.5z" fill="#a259ff" />
    <path d="M0 4.5C0 2 2 0 4.5 0H9v9H4.5C2 9 0 7 0 4.5z" fill="#f24e1e" />
    <path d="M9 0h4.5C16 0 18 2 18 4.5S16 9 13.5 9H9V0z" fill="#ff7262" />
    <path
      d="M18 13.5c0 2.5-2 4.5-4.5 4.5S9 16 9 13.5 11 9 13.5 9s4.5 2 4.5 4.5z"
      fill="#1abcfe"
    />
  </svg>
);

export default Figma;
