const projects = [
  {
    name: "Inovan.do",
    summary: ["work.inovando.p1", "work.inovando.p2"],
    href: "https://github.com/inovando",
    date: new Date(2020, 5),
    until: "now",
    techs: ["React.js", "React Native", "Styled Components", "Github"]
  },
  {
    name: "Camera Hot",
    summary: ["work.camerahot.p1", "work.camerahot.p2"],
    href: "https://camerahot.com/",
    date: new Date(2019, 0),
    until: new Date(2020, 1),
    techs: ["Vue.js", "Node.js", "CSS3"]
  },
  {
    name: "Agrotis Plataforma",
    summary: ["work.agrotis.p1", "work.agrotis.p2"],
    locked: true,
    date: new Date(2016, 10),
    until: new Date(2019, 0),
    href: "https://agrotis.io/#/",
    techs: ["React", "Typescript", "CSS3", "Angular 1.x"]
  },
  {
    name: "Estratégia Concursos",
    summary: ["work.estrategia_concursos.p1", "work.estrategia_concursos.p2"],
    href: "https://www.estrategiaconcursos.com.br",
    date: new Date(2016, 0),
    until: new Date(2016, 10),
    techs: ["HTML5", "CSS3", "jQuery", "Sass"]
  },
  {
    name: "Prata Fina",
    summary: ["work.prata_fina.p1", "work.prata_fina.p2"],
    href: "https://www.pratafina.com.br",
    date: new Date(2016, 0),
    until: new Date(2016, 10),
    techs: ["Vue.js", "HTML5", "Postcss"]
  },
  {
    name: "Previneo",
    summary: ["work.previneo.p1"],
    href: "https://www.previneo.com.br",
    date: new Date(2016, 7),
    until: new Date(2016, 7),
    techs: ["HTML5", "CSS3", "jQuery"]
  }
];

export default projects;
