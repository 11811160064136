export default {
  en: {
    title: "Hi, I am Patrick",
    subtitle: "Front-End Developer at Inovan.do",
    now: "Current",
    header: {
      skills: "Skills",
      thoughts: "Thoughts",
      work: "Work",
      setup: "Setup"
    },
    skills: {
      emailButton: "Lets work together!"
    },
    profile: {
      languagues: {
        portuguese: "Portuguese - Brazilian",
        english: "English"
      }
    },
    thoughts: {
      text: `<div>
          <p>
            I love my job as web developer and I found myself very happy within Front-End after a year working in the area. I have 7 years now with html, css and javascript consuming rest apis, developed 10+ projects (web and mobile) and i can say that many things have changed (for the better) since internet explorer 6 support. I have a lot of ideas for web components and boilerplates, most of them are at my <a href="https://github.com/santospatrick" target="_blank">github</a>, <a href="https://codepen.io/Piricuca/" target="_blank">codepen</a> and <a href="https://codesandbox.io/u/santospatrick" target="_blank">codesandbox</a> profiles. I am constantly studying about web development in <a href="http://egghead.io" target="_blank">egghead.io</a> and <a href="https://www.udemy.com/user/patrick-santos" target="_blank">udemy</a>. Also there are some values that I share and appreciate if my working environment has too:
          </p>
          <ul>
            <li><strong>Transparency</strong><span>, I love people that admit when they know and don’t know something, I think it helps a lot to identify the spot a team currently is so we can decide where to start and when to speed up or slow down</span></li>
            <li><strong>Diversity</strong>, distinct opinions and experiences make products better</li>
            <li><strong>Productivity</strong>, if you know me maybe you have seen how much extensions and plugins I have installed on my code editor and notebook. I am always looking for apps that makes me a better and faster developer. After some time working in a project you realize how much time you can save with keybindings, code snippets and boilerplates, i really love it</li>
            <li><strong>Communication</strong>, I like people that talks to me when something is wrong and also when something is right, I like to hear about new ideas, expectations and goals of a person or a group within a project, it makes life so much easier when everybody is aligned and knows where and how they want to achieve their goals</li>
            <li><strong>Continuous Improvement</strong>, I am always improving my way of writing code and the way I treat people at work, I like companies that invest in community, courses and travelling so we can all grow together</li>
          </ul>
          <p>
            All the companies I worked until now were presencial and built on teams and I improved a lot both my personal and technical skills. At the agencies I learned a lot about javascript bundling (webpack, es6, code split, UMD pattern), css preprocessors (sass, stylus, css resets, theming), image compressing, minification of everything, a little bit of <i>SEO</i> and <i>Vue.js</i> that we used for <a href="https://www.pratafina.com.br/" target="_blank">a big e-commerce of jewelry at the time</a>. After getting some experience in production with these tools I have made my first cool project in github which was a <a href="https://github.com/santospatrick/ultimate-toolkit" target="_blank">boilerplate plug and play</a> to focus on developing code and not configuring these robotic processes, accelerating the creation of websites in general. At the software companies I learned about <i>SCRUM</i>, people and project management and I started to focus my studies in <i>Angular 1.x</i> and <i>React</i> consuming Rest Apis to build systems for a lot of users (not only websites for one client anymore).
          </p>
          <p>
            I also graduated in Graphic Design at 2017, as you can see I posted some work at <a href="https://www.behance.net/santospatrick" target="_blank">behance</a>, and when I see potential or value, I post something there.
          </p>
          <p>
            After some time I kind of got tired of front end development & design and tried backend with <i>Node.js</i> (as I already knew enough of javascript). I wasn’t too excited because my first language in back end was <i>PHP</i> and the developer experience I had wasn’t the best. But man, node made everything so easy: environment configuration, starting a new project and getting a rest api up and running within minutes, deploy and it basically has support to run everywhere (even in raspberry pi!).
          </p>
          <p>
            But as you can see, I came back (and really quickly actually) to front end and I decided that I would master it, not matter how much i had to study. So I started to learn about complex animations with CSS, javascript design patterns, good practices, some advanced things like internationalization (also applied in this project) and everything that makes a good software, but keeping the code clean, coming back to refactor and improving performance of web apps.
          </p>
          <p>
            As I mentioned before, I worked on the mobile area too making 2 apps with <i>React Native</i> and I really like the technology, native experiences are kind of different from web responsive and I learned a lot designing screen flows and studying about Google Maps, GPS, camera and native features. Maybe it is not something I would work on weekdays, but temporary jobs are always welcome.
          </p>
        </div>
      `
    },
    work: {
      title: {
        freelances: "Freelances/Jobs",
        jobs: "Full Time Jobs",
        personal: "Personal Projects",
        design: "View all design projects"
      },
      currentyWorking: "Internal Solutions",
      inovando: {
        p1:
          "Custom internal web system solutions and mobile apps for micro and small companies with React.js and React Native",
        p2:
          "I also structured the company's open source projects at Github, where we can find templates developed for React.js, React Native, Adonis and a React.js components library (NPM Package). We use all these templates/components in all our front-end, mobile and back-end projects (no exception)."
      },
      camerahot: {
        p1:
          "Web system developed with Vue.js, Vuex & CSS Modules. I structured the project from the beginning with best javascript practices standardizing the code from concepts and techs like: ESLint, Prettier, Atomic Design, etc.",
        p2:
          "I also structured the Node.js project that was used for real-time functions with Babel, Nodemon & PM2 to improve productivity at development/production environments"
      },
      agrotis: {
        p1:
          "Multiple web systems developed with React.js, Styled Components and a project with only stateless reusable components composing the company's Design System.",
        p2:
          "Solutions for agrobusiness with a cloud infrastructure and digital remaking of manual processes of their users for over 25 years. I worked with 2 teams using React/AngularJS and at the project with reusable components."
      },
      estrategia_concursos: {
        p1:
          "E-learning platform with courses to prepare and train students for civil service exams.",
        p2:
          "I worked with the project's maintenance developed with jQuery and Sass."
      },
      prata_fina: {
        p1: "E-commerce web that sells jewelry (rings, earings, etc).",
        p2:
          "My first contact with Vue.js, i contributed with componentization of the system and styles structure based on Atomic Design concepts."
      },
      previneo: {
        p1:
          "Institutional website for a company that works with early diagnosis of the main types of cancer that occur in Brazil."
      },
      streq: {
        p1:
          "Mobile app developed with React Native. Hybrid solution (iOS/Android).",
        p2:
          "The project intends to help employed people identify their stress level through a quiz validated by a cientific research known as Streq-25."
      },
      tahnamao: {
        p1:
          "Mobile app developed with React Native. Hybrid solution (iOS/Android).",
        p2:
          "With this app you can find people to get jobs done like: cleaning a house or even develop an app layout"
      },
      qvt: {
        p1:
          "Mobile app developed with React Native. Hybrid solution (iOS/Android).",
        p2:
          "The project intends to help employed people check if their work/life balance is healthy and, after taking a quiz, the app shows how healthy you are."
      },
      clinicarx: {
        p1:
          "Mobile app developed with React Native's Official CLI, also used Styled Components to improve productivity on app's styles (once i am mainly a Web Developer) so i could write in common css language. Firebase was the choice for notifications. Hybrid solution (iOS/Android).",
        p2:
          "The project gives users access to their visits with doctors and which medicines they should take in a period of time, also users can rate drugstores."
      },
      giveit: {
        p1:
          "Web system and app for a university gradutation's job in Bachelor of Technology. I worked as Fullstack developing three parts of the project (Front, Back & Mobile) alone from beginning to end.",
        p2:
          "The project's objective was to help people donate clothes/food. With Google Maps integration, it was easy to find NGOs that needed these donations close to you and NGOs also could find people that donate inside the platform."
      },
      redweekideas: {
        p1: "Hotsite for a Marketing Agency that sells online courses."
      }
    },
    setup: {
      list: `
      <ul>
      <li>
        <strong>
          Editor: <i>VSCode</i>
        </strong>
        , after years at sublime text (and some using atom) I changed to VSCode
        because of ESLint support and I love the suggestions that are 99% of the
        time right! Also for React development the debugging flow with Chrome is
        awesome. Yes I tried vim and no, i am not a robot.
      </li>
      <li>
        <strong>
          Terminal: <i>Oh-my-zsh</i>
        </strong>
        , thats my currently terminal emulator, I cant live without text
        completions and syntax highlighting anymore, it makes me feel like a
        human using the terminal and not a monkey. I've made a
        <a
          href="https://gist.github.com/santospatrick/119ad6db081127ceff83182213d67586"
          target="_blank"
          rel="noopener noreferrer"
        >
          tutorial for setup
        </a>
        on linux. For mac it is pretty much the same, the difference is that I
        use
        <a
          href="https://www.iterm2.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          iTerm 2
        </a>
        and linux I use
        <a
          href="http://guake-project.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Guake
        </a>
        .
      </li>
      <li>
        <strong>
          Screen recording: <i>Quick Time Player</i>
        </strong>
        , on mac for 1080p60fps and
        <a
          href="https://launchpad.net/kazam"
          target="_blank"
          rel="noopener noreferrer"
        >
          Kazam
        </a>
        for linux.
      </li>
      <li>
        <strong>
          Screenshots: <i>cmd + shift + 4</i>
        </strong>
        , on mac for selected or full area and
        <a
          href="https://github.com/lupoDharkael/flameshot"
          target="_blank"
          rel="noopener noreferrer"
        >
          Flameshot
        </a>
        for linux.
      </li>
    </ul>
      `
    }
  },
  "pt-BR": {
    title: "Olá, eu sou o Patrick",
    subtitle: "Front-End Developer em Inovan.do",
    now: "Atual",
    header: {
      skills: "Habilidades",
      thoughts: "Considerações",
      work: "Trabalhos",
      setup: "Setup"
    },
    skills: {
      emailButton: "Vamos trabalhar juntos!"
    },
    profile: {
      languagues: {
        portuguese: "Português - Brasil",
        english: "Inglês"
      }
    },
    thoughts: {
      text: `<div>
          <p>
            Eu amo meu trabalho como web developer e posso dizer que me encontrei com o desenvolvimento Front-end depois de trabalhar 1 ano na área. Hoje tenho 7 anos com html, css e javascript consumindo Apis Rest, já desenvolvi mais de 10 projetos (web e mobile) e posso dizer que muita coisa mudou (para melhor) desde os tempos de suporte ao IE6. Eu tenho várias ideias de componentes web e boilerplates, a maioria deles são públicos e estão nos meus perfis do <a href="https://github.com/santospatrick" target="_blank">github</a>, <a href="https://codepen.io/Piricuca/" target="_blank">codepen</a> e <a href="https://codesandbox.io/u/santospatrick" target="_blank">codesandbox</a>. Estou constantemente estudando desenvolvimento web no <a href="http://egghead.io" target="_blank">egghead.io</a> e <a href="https://www.udemy.com/user/patrick-santos" target="_blank">udemy</a>. Além disso compartilho de alguns valores e gosto de trabalhar em ambientes que contenham:
          </p>
          <ul>
            <li><strong>Transparência</strong><span>, eu amo pessoas que admitem quando sabem ou não sobre algum assunto, eu acho que ajuda muito a identificar em que nível o time está e, após analisar isso, decidir se podemos avançar ou se devemos nivelar conhecimento</span></li>
            <li><strong>Diversidade</strong>, opiniões e experiências diferentes fazem produtos melhores</li>
            <li><strong>Produtividade</strong>, se você me conhece sabe que eu tenho 1 mihão de extensões e plugins instalados no VSCode e na máquina. Eu sempre estou procurando algum aplicativo ou forma de aproveitar melhor meu tempo para desenvolver melhor e mais rápido. Depois de algum tempo desenvolvendo projetos você se toca de quanto tempo poderia ter economizado com code snippets, teclas de atalho e boilerplates, eu curto muito esses tipos de ferramenta</li>
            <li><strong>Comunicação</strong>, eu gosto de pessoas que se comunicam comigo quando algo está errado ou certo, eu gosto de escutar sobre novas ideias, expectativas e objetivos de uma pessoa ou grupo para um projeto, quando todos estão alinhados e sabem onde e como querem chegar fica muito fácil de atingir metas</li>
            <li><strong>Melhoria Contínua</strong>, eu sempre estou melhorando a minha forma de codar e a forma de tratar pessoas no meu trabalho. Eu gosto de empresas que investem em comunidade, cursos e viagens para que todos possamos crescer juntos</li>
          </ul>
          <p>
            Todas as empresas que eu trabalhei até hoje foram presenciais (seguindo o padrão de times) e eu consegui desenvolver muito meu lado pessoal e técnico. Nas agências eu aprendi muito sobre javascript bundling (webpack, es6, code split, UMD pattern), css preprocessors (sass, stylus, css resets, theming), compressão de imagem, minificação de tudo isso, um pouco de <i>SEO</i> e <i>Vue.js</i> que usamos para fazer <a href="https://www.pratafina.com.br/" target="_blank">um grande e-commerce de jóias</a> na época. Depois de adquirir alguma experiência em produção com essas ferramentes eu desenvolvi meu primeiro projeto legal no github que era um <a href="https://github.com/santospatrick/ultimate-toolkit" target="_blank">boilerplate com webpack + gulp</a> para usar em projetos com o básico do básico configurado e com foco no desenvolvimento do código mesmo, e não ficar configurando ambiente. Nas empresas de software eu aprendi sobre <i>SCRUM</i> e seus eventos, gestão de pessoas e projetos e comecei a focar meus estudos no <i>Angular 1.x</i> e <i>React</i> consumindo Apis Rest para construir sistemas com uma base de usuários (e não mais sites só para 1 cliente).
          </p>
          <p>
            Eu me formei em Design Gráfico em 2017, postei alguns trabalhos desenvolvidos no <a href="https://www.behance.net/santospatrick" target="_blank">behance</a>, e quando eu vejo algum valor ou potencial, eu posto alguma coisa lá.
          </p>
          <p>
            Depois de algum tempo eu cansei do front e design e fui tentar desenvolver alguma coisa bom backend em <i>Node.js</i> (já que eu sabia bastante de javascript). Eu não estava tão animado porquê a minha primeira linguagem de back-end foi PHP e a minha experiência não tinha sido tão legal. Mas com node tudo é muito simples: configuração de ambiente, começar um novo projeto (existem milhões de boilerplates no github) e subir uma api rest em minutos, facilidade no deploy e basicamente tem suporte pra rodar em qualquer lugar (até no raspberry pi!).
          </p>
          <p>
            Mas como você pode perceber, eu voltei (e bem rápido na verdade) para o front e decidi que eu ia masterizar essa arte, não importava o quando eu ia ter que estudar. Então eu comecei a aprender sobre animações complexas com CSS, padrões de projeto com javascript, boas práticas, algumas coisas avançadas como internacionalização (inclusive aplicado nesse projeto) e tudo que faz um um bom software, mas mantendo o código limpo, voltando de tempos em tempos para refatorar e melhorando a performance de aplicações web ainda mais.
          </p>
          <p>
            Como eu mencionei antes eu trabalhei na área de mobile também, já desenvolvi 2 aplicativos com <i>React Native</i> e eu curti bastante a tecnologia, experiências nativas são bem diferentes da web responsiva e eu aprendi bastante com o desenvolvimento de layouts e fluxos de tela com Google Maps, GPS, camera e funcionalidades nativas. Talvez não seja algo que eu trabalharia todo dia, mas freelas são sempre bem-vindos.
          </p>
        </div>
      `
    },
    work: {
      title: {
        freelances: "Freelances/Entregas",
        jobs: "Experiência",
        personal: "Projetos pessoais",
        design: "Ver todos os projetos de design"
      },
      currentyWorking: "Soluções Internas",
      inovando: {
        p1:
          "Soluções personalizadas para sistemas internos e aplicativos para micro e pequenas empresas com React.js e React Native.",
        p2:
          "Também estruturei toda a parte open-source da empresa pelo Github, onde podemos encontrar templates desenvolvidos para React.js, React Native, Adonis e também uma biblioteca de componentes para React.js. Usamos esses templates/componentes em todos os projetos front-end, mobile e back-end (sem excessão)."
      },
      camerahot: {
        p1:
          "Sistema web em desenvolvimento com Vue.js, Vuex e CSS Modules. Estruturei o projeto desde o início com as melhores práticas de projetos Javascript padronizando o código a partir de conceitos e tecnologias como: ESLint, Prettier, Atomic Design, etc.",
        p2:
          "Também estruturei um projeto back-end em Node.js para funcionalidades real time com Babel, Nodemon e PM2 para aumentar a produtividade dos ambientes de desenvolvimento/produção."
      },
      agrotis: {
        p1:
          "Múltiplos sistemas web desenvolvidos com React.js, Styled Components e separação de projeto com componentes reutilizados entre 3 projetos diferentes para composição de um Design System.",
        p2:
          "Soluções para o agronegócio a partir de uma infraestrutura Cloud e transformação digital de processos manuais de clientes há mais de 25 anos. Trabalhei em 2 times com React/Angular e na estruturação do projeto de componentes reutilizáveis."
      },
      estrategia_concursos: {
        p1:
          "Sistema web EAD com conteúdo de preparação e capacitação de alunos para concursos públicos.",
        p2: "Trabalhei com suporte do projeto desenvolvido com jQuery e Sass."
      },
      prata_fina: {
        p1: "E-commerce web para vendas de jóias (anéis, brincos e etc).",
        p2:
          "Meu primeiro contato com Vue.js, contribui com a componentização do sistema e organização de estilos a partir dos conceitos de Atomic Design."
      },
      previneo: {
        p1:
          "Site institucional para uma empresa que trabalha na realização do diagnóstico precoce dos principais tipos de câncer que ocorrem no Brasil."
      },
      streq: {
        p1:
          "Aplicativo desenvolvido com React Native. Solução híbrida (iOS/Android).",
        p2:
          "O projeto auxilia trabalhadores a identificar seu nível de stress através de um questionário validado por um estudo científico conhecido como Streq-25."
      },
      tahnamao: {
        p1:
          "Aplicativo desenvolvido com React Native. Solução híbrida (iOS/Android).",
        p2:
          "Com esse aplicativo você encontra pessoas para fazer tarefas como por exemplo: limpar uma casa ou até mesmo desenvolver um layout de um aplicativo"
      },
      qvt: {
        p1:
          "Aplicativo desenvolvido com React Native. Solução híbrida (iOS/Android).",
        p2:
          "O projeto auxilia pessoas a identificar se a sua qualidade de vida no trabalho está saudável e, após responder algumas perguntas, mostra um gráfico identificando o quão saudável você está."
      },
      clinicarx: {
        p1:
          "Aplicativo desenvolvido com a CLI oficial do React Native, Styled Components para organização de estilos/componentes dumb e Firebase para notificações. Solução híbrida (iOS/Android).",
        p2:
          "O projeto consiste em conceder ao usuário acesso ao seu histórico de atendimentos, medicamentos solicitados pelo médico e avaliações de Farmácias."
      },
      giveit: {
        p1:
          "Sistema web e aplicativo para um trabalho de conclusão de curso em análise de sistemas. Trabalhei como Full-Stack, desenvolvi os 3 projetos (Front, Back e Mobile) sozinho do início ao fim.",
        p2:
          "O objetivo do projeto era facilitar a doação de roupas/comida. A partir de integração com o Google Maps, era possível encontrar ONGs que precisavam de doações perto de você e as próprias ONGs poderiam encontrar doadores também dentro da plataforma."
      },
      redweekideas: {
        p1: "Hotsite para uma agência de Marketing que vende cursos online."
      }
    },
    setup: {
      list: `
      <ul>
      <li>
        <strong>
          Editor: <i>VSCode</i>
        </strong>
        , depois de anos usando sublime text (e alguns usando atom) estou usando o VSCode por causa do suporte ao ESLint e eu amo as sugestões que vem na hora certa 99% das vezes! O fluxo de trabalho de debug com React e Chrome também é fantástico. Sim eu já tentei usar o vim e não, não sou um robô.
      </li>
      <li>
        <strong>
          Terminal: <i>Oh-my-zsh</i>
        </strong>
        , esse é o meu emulador de terminal atual, eu não consigo mais viver sem as sugestões de comandos e syntax highlighting, essa ferramenta me faz sentir como um humano usando o terminal e não um macaco naquela telinha preta baseada em 50 tons de cinza. Eu fiz um
        <a
          href="https://gist.github.com/santospatrick/119ad6db081127ceff83182213d67586"
          target="_blank"
          rel="noopener noreferrer"
        >
          tutorial para configuração
        </a>
        no linux. No mac é quase a mesma coisa, a única diferença é que eu uso 
        <a
          href="https://www.iterm2.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          iTerm 2
        </a>
        e no linux eu uso o
        <a
          href="http://guake-project.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Guake
        </a>
        .
      </li>
      <li>
        <strong>
          Gravação de tela: <i>Quick Time Player</i>
        </strong>
        , no mac pra conseguir 1080p60fps e
        <a
          href="https://launchpad.net/kazam"
          target="_blank"
          rel="noopener noreferrer"
        >
          Kazam
        </a>
        no linux.
      </li>
      <li>
        <strong>
          Captura de tela: <i>cmd + shift + 4</i>
        </strong>
        , no mac para áreas selecionadas, janelas ou fullscreen e
        <a
          href="https://github.com/lupoDharkael/flameshot"
          target="_blank"
          rel="noopener noreferrer"
        >
          Flameshot
        </a>
        no linux.
      </li>
    </ul>
      `
    }
  }
};
