import React from "react";

const Rotating = props => (
  <svg width={368} height={356} fill="none" {...props}>
    <path
      d="M184 47l-20.8 36h41.6L184 47zM171.3 308.5l25.4-25.5M196.7 308.5L171.3 283M302 198.5c11.046 0 20-8.954 20-20s-8.954-20-20-20-20 8.954-20 20 8.954 20 20 20zM78.8 196.5H49.2c-1.8 0-3.2-1.4-3.2-3.2v-29.5c0-1.8 1.4-3.2 3.2-3.2h29.5c1.8 0 3.2 1.4 3.2 3.2v29.5c.1 1.8-1.3 3.2-3.1 3.2zM276.4 270.2L265.7 230l-29.4 29.4 40.1 10.8zM100.6 76.3v36M82.6 94.3h36M100 280.6c11.046 0 20-8.954 20-20s-8.954-20-20-20-20 8.954-20 20 8.954 20 20 20zM245.1 90L266 69.2c1.3-1.3 3.3-1.3 4.6 0L291.5 90c1.3 1.3 1.3 3.3 0 4.6l-20.9 20.9c-1.3 1.3-3.3 1.3-4.6 0l-20.9-20.9c-1.2-1.2-1.2-3.3 0-4.6z"
      stroke="#fff"
      strokeWidth={3}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Rotating;
