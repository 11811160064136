import React from "react";

const Typescript = props => (
  <svg viewBox="0 0 256 256" {...props}>
    <path fill="#007acc" d="M0 128v128h256V0H0z" />
    <path
      className="st1"
      d="M56.6 128.8l-.1 10.5h33.4V234h23.6v-94.6h33.4v-10.3c0-5.7-.1-10.4-.3-10.6-.1-.2-20.4-.2-45-.2l-44.7.1-.3 10.4zM206.6 118.1c6.5 1.6 11.5 4.5 16 9.2 2.4 2.5 5.9 7.1 6.1 8.2.1.3-11.1 7.8-17.8 12-.2.2-1.2-.9-2.3-2.5-3.3-4.8-6.7-6.9-12-7.2-7.8-.5-12.8 3.5-12.7 10.3 0 2 .3 3.2 1.1 4.8 1.7 3.5 4.9 5.6 14.8 10 18.3 7.9 26.2 13.1 31 20.5 5.4 8.2 6.7 21.4 3 31.2-4.1 10.6-14.1 17.9-28.3 20.3-4.4.8-14.8.7-19.5-.2-10.3-1.8-20-6.9-26-13.6-2.4-2.6-6.9-9.4-6.7-9.9.1-.2 1.2-.8 2.4-1.5 1.1-.7 5.4-3.1 9.5-5.5l7.4-4.3 1.5 2.3c2.2 3.3 6.9 7.8 9.7 9.3 8.2 4.3 19.4 3.7 24.9-1.3 2.4-2.2 3.3-4.4 3.3-7.7 0-3-.4-4.3-1.9-6.5-2-2.8-6.1-5.2-17.6-10.2-13.2-5.7-18.9-9.2-24.1-14.8-3-3.3-5.9-8.5-7-12.8-1-3.6-1.2-12.7-.4-16.3 2.7-12.8 12.4-21.7 26.3-24.3 4.4-.9 14.8-.6 19.3.5z"
    />
  </svg>
);

export default Typescript;
